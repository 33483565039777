import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

export class PseudoValidator<T = any> implements Validators {

  private sub: Subscription;

  host = {
    formControl: new FormControl<T>(null)
  };

  errorMessage: string;

  identity = 'pseudoValidator';

  constructor(validateFn: (arg?: T) => boolean, updateObservable?: Observable<T>, errorMessage?: string) {

    this.errorMessage = errorMessage;

    this.host.formControl.addValidators(control => {
      const value = control?.value as T;
      const res = validateFn(value);

      const error: ValidationErrors = {};
      error[this.identity] = (this.errorMessage || 'error');

      return res ? null : error;
    });

    this.sub = updateObservable?.subscribe(_ => this.updateValueAndValidity(_));

  }

  unsubscribe() {
    this.sub?.unsubscribe();
  }

  updateValueAndValidity(value?: T) {
    this.host.formControl.setValue(value);
  }

}
