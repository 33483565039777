<ng-container *ngIf="dataSource$ | async as dataSource">

  <ng-container [getVar]="(allowUserInput$ | async)" #getAllowUserInput="getVar">

    <app-input
      [ngClass]="['app-dropdown-input', getAllowUserInput.value ? 'app-allow-user-input' : '', (required ? 'app-input-required' : '')]"
      [value]="inputValue"
      [valueTemplate]="dropdownInputValueTemplate"
      [readonly]="!dataSource.data.allowUserInput"
      [placeholder]="placeholder"
      [labelless]="labelless"
      [label]="label"
      [tooltip]="tooltip"
      iconType="custom"
      [customIconType]="getAllowUserInput.value ? 'CLOSE' : 'CARET_DOWN'"
      (custom)="customHandler($event)"
      (activateElement)="activateElementHandler($event)"
      [colorTheme]="'NONE'"
      [disabled]="disabled"
    (info)="clickInfo($event)"
    [showInfoIcon]="showInfoIcon"
      [renderingData]="dataSource.inputRenderingData"
      (inputText)="onInputText($event)"
      cancelBrowserAutocomplete
      [removeInnerIconFromTabOrder]="getAllowUserInput.value"
      [triggerFocus]="dataSource.inputFocusTrigger"
      triggerSelector="input"
      triggerDelay="200"
      #input
    ></app-input>

    <app-menu #menu [options]="menuComponentOptions$ | async" [elementsPartOfMenu]="[input]"></app-menu>

  </ng-container>

</ng-container>
