/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { LanguageService, TranslationService, WindowRef } from '@spartacus/core';
import { coerceBoolean } from '@util/functions/objects';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { filter, map, skip, switchMap, take } from 'rxjs/operators';
import { UtilCustomCSSPropertyService } from '../../services/util-custom-css-property.service';
import { CheckboxItem } from '../checkbox-button-group/checkbox-group-data-source';
import { MenuComponent, MenuComponentOptions } from '../menu/menu.component';
import { DropdownDataSource, DropdownItem } from './dropdown-data-source.class';
import { UtilOmnipresentFormGroupService } from '@util/services/util-omnipresent-form-group.service';
import { PseudoValidator } from '@util/classes/pseudo-validator.class';
import { InputComponent } from '../input/input.component';
import { ValidationErrors } from '@angular/forms';
import { addFocusGroupEventListener } from '@util/functions/events';

const startWithFilterFn = (term: string, item: CheckboxItem<any, any>): boolean => {if (!term) { return true;} return item?.label?.startsWith(term);};

const startWithLowercaseFilterFn = (term: string, item: CheckboxItem<any, any>): boolean => {
  if (!term) { return true;}
  return item?.label?.toLocaleLowerCase()?.startsWith(term);
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnDestroy {

  private elementRef = inject(ElementRef);
  private utilCustomCSSPropertyService = inject(UtilCustomCSSPropertyService);
  private translationService = inject(TranslationService);
  private languageService = inject(LanguageService);
  private utilOmnipresentFormGroupService = inject(UtilOmnipresentFormGroupService);
  private windowRef = inject(WindowRef);

  private _dataSourceBehaviorSubject = new BehaviorSubject<DropdownDataSource>(null);
  private _inputResizeObserver: ResizeObserver;
  private _selectedItemsChangeSubscription: Subscription;
  private _checksRegistrationOfPseudoValidatorInFormGroupServiceSubscription: Subscription;

  private _dropdownMenuBehaviorSubject = new BehaviorSubject<MenuComponent>(null);
  private _languageChangeSubscription: Subscription;
  private _newItemsSubscription: Subscription;
  private _disabled: boolean;
  private _labelless: boolean;
  private _requiredBehaviorSubject = new BehaviorSubject<boolean>(false);
  private _formGroupNameBehaviorSubject = new BehaviorSubject<string>(null);
  private _showInfoIcon: boolean;
  private _addingValidatorToInnerInputComponentSubscription: Subscription;
  private _validateOnMenuCloseSubscription: Subscription;

  private focusGroupEventListenerObj: ReturnType<typeof addFocusGroupEventListener>;

  private _inputComponentBehaviorSubject = new BehaviorSubject<InputComponent>(null);

  private _userTypedSinceFocusFlag = false;

  private requiredPseudoValidator = new PseudoValidator(
    _ => {
      const errors = this.sharedRequireValidationFn();
      return errors ? (Object.values(errors).filter(val => !!val).length === 0) : true;
    },
    this.dataSource$.pipe(
      filter(_ => !!_),
      switchMap(ds => ds.selectedItems$)
    )
  );

  @ViewChild('menu', { read: MenuComponent })
  set dropdownMenu(value: MenuComponent) {
    this._dropdownMenuBehaviorSubject.next(value);
  }

  @ViewChild('input', { read: InputComponent })
  set inputComponent(value: InputComponent) {
    this._inputComponentBehaviorSubject.next(value);
  }

  get inputComponent(): InputComponent {
    return this._inputComponentBehaviorSubject.value;
  }

  @HostBinding('class')
  private get classes() :string[] {
    const classes: string[] = [];
    if (this.isOpen) {
      classes.push('app-dropdown-is-open');
    }
    return classes;
  }


  @HostListener('keydown', ['$event'])
  private pressedDown(e: KeyboardEvent) {

    const killEvent = (e: Event, andPrevent = false) => {
      e.preventDefault();
      e.stopPropagation();

      if (andPrevent) {
        const keyUpFn = (e: KeyboardEvent) => {
          e.preventDefault();
          (e.target as HTMLElement).removeEventListener('keyup', keyUpFn);
        };
        (e.target as HTMLElement).addEventListener('keyup', keyUpFn);
      }
    };

    if (this.windowRef.nativeWindow.document.activeElement.nodeName === 'INPUT') {
      if (e.code === 'ArrowUp' && this.dataSource) {
        const nowItem = this.dataSource.internalCheckboxGroupDataSource.focusUp();
        if (nowItem) {
          this.scrollToCheckboxWith(nowItem.label);
          killEvent(e, true);
        }

      }
      if (e.code === 'ArrowDown' && this.dataSource) {
        this.openMenu();
        const nowItem = this.dataSource.internalCheckboxGroupDataSource.focusDown();
        if (nowItem) {
          this.scrollToCheckboxWith(nowItem.label);
          killEvent(e, true);
        }

      }
      if (e.code === 'Enter' && this.dataSource) {
        const nowItem = this.dataSource.internalCheckboxGroupDataSource.getFocusedItem();
        if (nowItem) {
          this.dataSource.selectItemWithAttribute('value', nowItem.value);
          killEvent(e);
        }

      }
    }
    if (e.code === 'Escape') {
      if (this.isOpen) {
        this.toggleDropdown(e);
        killEvent(e);
      }
    }
  }

  get isOpen(): boolean {
    return this.dropdownMenu?.isOpen;
  }

  get dropdownMenu$(): Observable<MenuComponent> {
    return this._dropdownMenuBehaviorSubject.asObservable();
  }

  get dropdownMenu(): MenuComponent {
    return this._dropdownMenuBehaviorSubject.value;
  }

  @Input()
  label: string;

  get disabled(): boolean {
    return this._disabled;
  }

  @Input()
  set disabled(value: boolean) {
    this._disabled = coerceBoolean(value);
  }

  get required(): boolean {
    return this._requiredBehaviorSubject.value;
  }

  @Input()
  set required(value: boolean) {
    this._requiredBehaviorSubject.next(coerceBoolean(value));
  }

  get formGroupName(): string {
    return this._formGroupNameBehaviorSubject.value;
  }

  @Input()
  set formGroupName(value: string) {
    this._formGroupNameBehaviorSubject.next(value);
  }

  @Input()
  set labelless(value: boolean) {
    this._labelless = coerceBoolean(value);
  }

  get labelless(): boolean {
    return this._labelless;
  }

  get showInfoIcon(): boolean {
    return this._showInfoIcon;
  }

  @Input()
  set showInfoIcon(value: boolean) {
    this._showInfoIcon = coerceBoolean(value);
  }

  inputValue = '';

  get menuComponentOptions$(): Observable<MenuComponentOptions> {
    return this.dataSource?.menuComponentOptions$;
  }

  get dataSource$(): Observable<DropdownDataSource> {
    return this._dataSourceBehaviorSubject.asObservable();
  }

  get dataSource(): DropdownDataSource {
    return this._dataSourceBehaviorSubject.value;
  }

  @Input()
  set dataSource(value: DropdownDataSource) {
    this._selectedItemsChangeSubscription?.unsubscribe();
    this._newItemsSubscription?.unsubscribe();
    if (value) {

      value.getMenuMaxHeight = () => {
        return this.getContentHeightAccordingToItems(value);
      };

      value.dropdownMenuRef$ = this.dropdownMenu$;
      this._selectedItemsChangeSubscription = value.selectedItems$.subscribe(items => {

        this.inputValue = items.map(item => {
          let label = item.label;
          if (item.labelTranslationKey) {
            this.translationService.translate(item.labelTranslationKey).pipe(take(1)).subscribe(tr => label = tr);
          }
          return label;
        }).join(', ') || '';

      });
    }
    this._dataSourceBehaviorSubject.next(value);
    setTimeout(() => {
      this.updateResizeObserver();
    });
  }

  allowUserInput$ = this.dataSource$.pipe(map(ds => ds?.data.allowUserInput));

  @Input()
  placeholder: string;

  @Input()
  tooltip: string;

  private _dropdownInputValueTemplate: TemplateRef<any>;

  @Input()
  set dropdownInputValueTemplate(value: TemplateRef<any>) {
    this._dropdownInputValueTemplate = value;
  }

  get dropdownInputValueTemplate(): TemplateRef<any> {
    return this._dropdownInputValueTemplate;
  }

  @Output()
  readonly info = new EventEmitter<UIEvent>();

  ngOnInit(): void {

    this._languageChangeSubscription =
    this.languageService.getActive().subscribe(_ => {
      this.dataSource$.pipe(filter(_ => !!_), take(1)).subscribe(ds => {

        const observableArray: Observable<string>[] = [];

        ds.getItems()?.forEach(item => {

          if (item.labelTranslationKey) {
            if (this.translationService) {
              observableArray.push(this.translationService.translate(item.labelTranslationKey).pipe(take(1)));
            } else {
              observableArray.push(of(item.label));
            }
          } else {
            observableArray.push(of(item.label || ''));
          }
        });

        forkJoin(observableArray).pipe(take(1)).subscribe(translations => {
          ds.getItems()?.forEach((item, i) => {
            item.label = translations[i];
          });
          ds.selectedItems$.pipe(take(1)).subscribe(items => {
            this.inputValue = items.map(item => {
              let label = item.label;
              if (item.labelTranslationKey) {
                this.translationService.translate(item.labelTranslationKey).pipe(take(1)).subscribe(tr => label = tr);
              }
              return label;
            }).join(', ') || '';
          });
          // this.cdr.detectChanges();
        });

      });
    });

    let oldFormGroupName: string;

    // adds the requiredPseudoValidator to the given formGroupName if it is required
    this._checksRegistrationOfPseudoValidatorInFormGroupServiceSubscription =
    combineLatest([
      this._requiredBehaviorSubject,
      this._formGroupNameBehaviorSubject
    ]).subscribe(([isRequired, name]) => {

      const oldHasIt = this.utilOmnipresentFormGroupService.hasValidator(oldFormGroupName, this.requiredPseudoValidator);
      const hasIt = this.utilOmnipresentFormGroupService.hasValidator(name, this.requiredPseudoValidator);

      if (oldFormGroupName !== name && oldHasIt) {
        this.utilOmnipresentFormGroupService.unregisterValidator(oldFormGroupName, this.requiredPseudoValidator);
      }

      oldFormGroupName = name;

      if (isRequired && name && !hasIt) {
        this.utilOmnipresentFormGroupService.registerValidator(name, this.requiredPseudoValidator);
      }
    });

    // if the inner InputComponent was found
    // a new required ValidatorFn is added to its formControl
    this._addingValidatorToInnerInputComponentSubscription =
    this._inputComponentBehaviorSubject.pipe(
      filter(_ => !!_),
    ).subscribe(cmp => {
      cmp.formControl.addValidators(this.sharedRequireValidationFn);
    });

    // validates the inner component even if the menu is closed and no item was selected
    // Note: important because only if another item is selected the inner component's value changes
    this._validateOnMenuCloseSubscription =
    this.dataSource$.pipe(
      filter(_ => !!_),
      switchMap(ds => ds.dropdownMenuRef$),
      filter(_ => !!_),
      switchMap(ref => ref.open$),
      skip(1) // skip init value of open$
    ).subscribe(isOpen => {
      if (!isOpen) {
        this._inputComponentBehaviorSubject.pipe(
          filter(_ => !!_),
          take(1)
        ).subscribe(cmp => {
          cmp.formControl.updateValueAndValidity();
          cmp.formControl.markAsTouched();
        });
      }

    });

    // ---------- FOCUS EVENTS

    const host = this.elementRef?.nativeElement as HTMLElement;
    this.focusGroupEventListenerObj = addFocusGroupEventListener(host, {
      focusgroupin: e => {
        this._userTypedSinceFocusFlag = false;

        // Use case: we have 2 dropdowns next to each other. Second dropdown is deactivated.
        // User expects that the written input of dropdown 1 will enabled dropdown 2 on "focusgroupout" and user
        // continues to navigates to the 2nd dropdown with tab key.
        // The Problem: While tabbing from dropdown 1 to 2, the <input> element of 2 is disabled and not in the tab order,
        // causing to switch the focus directly to <app-icon>,  but the user expects it to focus on <input>
        // the following solution is not perfect but sufficient

        // const focusedElement = e.lastFocusInEvent.target as HTMLElement;
        const focusedElement = this.windowRef.document.activeElement;
        if (focusedElement?.nodeName === 'APP-ICON' && !this.getInputTextValue()) {
          this.dataSource?.inputFocusTrigger.next(true);
        }

      },
      focusgroupout: e => {

        let term = this.getInputTextValue();
        const current = this.dataSource.selectedItems[0];
        // test if an item exists for the text in text input exist

        // const possibleItems = this.dataSource.getPossibleItemsThatInputFinds(term);
        const matchingItem = this.dataSource.getItemWithAttribute('label', term);

        if (this._userTypedSinceFocusFlag && this.dataSource.data.allowUserInput) {

          let possibleFreeItem: CheckboxItem<any, any> = {
            value: this.dataSource.data.freeTextToValueFunction ? this.dataSource.data.freeTextToValueFunction(term) : term,
            label: term,
            isFreeText: true
          };

          if (this.dataSource.data.freeTextItemUpdateFunction) {
            possibleFreeItem = this.dataSource.data.freeTextItemUpdateFunction(possibleFreeItem);
          }

          if (this.dataSource.data.allowFreeText === 'never' && !matchingItem) {
            this.clearComponent();
            term = '';
          }

          this.userInputDecisionOnBlurFn(e.lastFocusOutEvent);

          // if the user is allowed to input text freely and this text is different from the currently selected item (its label)...
          if (current?.label !== possibleFreeItem.label) {
            // if the freely input text does not match with an existing item AND free text is allowed then select it as free text
            if (!matchingItem && (this.dataSource.data.allowFreeText === 'always' || this.dataSource.data.allowFreeText === 'function')) {
              this.dataSource.selectFreeTextItem(possibleFreeItem).pipe(take(1)).subscribe(isSuccesful => {
                if (!isSuccesful) {
                  this.clearComponent();
                }
              });
            } else {
              // if not then select an item with the same label
              const successful = this.dataSource.selectItemWithAttribute('label', term, true);
              if (!successful) {
                this.clearComponent();
              }
            }
          }

        }

        this.closeMenu();
      },
      focusin: e => {
        const el = e.target as HTMLElement;
        if (el.nodeName === 'INPUT') {
          this.openMenu();
        }
      }
    });

  }

  ngOnDestroy(): void {
    this._inputResizeObserver?.disconnect();
    this._selectedItemsChangeSubscription?.unsubscribe();
    this._languageChangeSubscription?.unsubscribe();
    this._newItemsSubscription?.unsubscribe();
    this.requiredPseudoValidator.unsubscribe();
    this._checksRegistrationOfPseudoValidatorInFormGroupServiceSubscription?.unsubscribe();
    this._addingValidatorToInnerInputComponentSubscription?.unsubscribe();
    this._validateOnMenuCloseSubscription?.unsubscribe();
    if (this.formGroupName) {
      this.utilOmnipresentFormGroupService.unregisterValidator(this.formGroupName, this.requiredPseudoValidator);
    }
    this.focusGroupEventListenerObj.removeEventListener();
  }

  openMenu(e?: UIEvent) {
    if (!this.isOpen && !this.disabled) {
      this.toggleDropdown(e);
    }
  }

  closeMenu(e?: UIEvent) {
    if (this.isOpen) {
      this.toggleDropdown(e);
    }
  }

  toggleDropdown(e?: UIEvent) {

    const isAboutToOpen = !this.isOpen;

    if(!this.disabled) {
      this.dropdownMenu?.toggle();

      //#region - pre filter on opening
      if (isAboutToOpen && this.dataSource?.data?.userInputImpactOnItems === 'filter') {
        const realInput = this.getInputTextValue();
        this.dataSource?.search(realInput, startWithFilterFn);
      }

      if (isAboutToOpen && this.dataSource?.data?.userInputImpactOnItems === 'scroll') {
        const realInput = this.getInputTextValue();
        this.scrollToCheckboxWith(realInput);
      }
      //#endregion
    }
  }

  activateElementHandler(e: UIEvent) {
    this.openMenu(e);
  }

  customHandler(e: UIEvent) {

    this.allowUserInput$.pipe(take(1)).subscribe(isAllowed => {
      if (isAllowed) {
        this.clearComponent();
        this.dataSource.inputFocusTrigger.next(true);
      } else {
        this.openMenu(e);
      }
    });
  }

  clickInfo(e: UIEvent) {
    this.info.emit(e);
  }

  /**
   * get the input text in the <input> el
   */
  getInputTextValue(): string {
    const dropDownEl = this.elementRef.nativeElement as HTMLElement;
    const inputEl = dropDownEl.querySelector('input');
    return inputEl?.value || '';
  }

  /**
   * set the input text in the <input> el
   */
  setInputTextValue(value?: string) {
    const dropDownEl = this.elementRef.nativeElement as HTMLElement;
    const inputEl = dropDownEl.querySelector('input');
    if (inputEl) {
      inputEl.value = value || '';
    }
  }

  clearComponent() {
    this.dataSource.deselectAll();
    this.setInputTextValue('');
    this.dataSource.triggerMarkForChange();
  }

  private userInputDecisionOnEnterFn = (e: KeyboardEvent) => {
    if (e?.key === 'Enter') {
      const fe = {target: e.target};
      this.userInputDecisionOnBlurFn(fe);
    }
  };

  private userInputDecisionOnBlurFn = (e: Partial<FocusEvent>) => {
    const dropDownEl = this.elementRef.nativeElement as HTMLElement;
    const inputEl = dropDownEl.querySelector('input');
    const currentInputValue = inputEl.value;
    this.dataSource?.makeDecisionOnUserInput(currentInputValue, inputEl);
    // removes itself and its keydown pendant from the element
    inputEl.removeEventListener('keydown', this.userInputDecisionOnEnterFn);
  };

  onInputText(e: InputEvent) {

    if (this.dataSource?.data?.allowUserInput) {

      this.openMenu();

      const val = (e.target as HTMLInputElement).value;
      if (this.dataSource?.data?.userInputImpactOnItems === 'filter') {
        this.dataSource?.search(val?.toLocaleLowerCase(), startWithLowercaseFilterFn);
      }

      if (this.dataSource?.data?.userInputImpactOnItems === 'scroll') {
        this.scrollToCheckboxWith(val);
      }

      this._userTypedSinceFocusFlag = true;

      const host = this.elementRef?.nativeElement as HTMLElement;
      const input = host.querySelector('input') as HTMLInputElement;

      // if onInputText() has not been called for the first time, then this line will remove the old event listener
      input.removeEventListener('keydown', this.userInputDecisionOnEnterFn);

      // sets the userInputDecisionOnKeydownFn as the keydown event listener
      input.addEventListener('keydown', this.userInputDecisionOnEnterFn);
    }

  }

  private updateResizeObserver() {

    this._inputResizeObserver?.disconnect();

    const host = this.elementRef?.nativeElement as HTMLElement;
    const appInput = host.querySelector('app-input');

    if (appInput) {
      this._inputResizeObserver = new ResizeObserver(_ => {
        const inputWidth = _?.[0]?.contentRect?.width;
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        this.utilCustomCSSPropertyService.setValue('--input-width', (inputWidth || 200) + 'px', host);
      });
      this._inputResizeObserver.observe(appInput);
    }

  }

  private getContentHeightAccordingToItems(ds: DropdownDataSource): string {
    const max = ds?.data?.maxVisibleItems;
    let value: string = null;
    if (typeof max === 'number' && max < ds.getItems()?.length) {
      const height = this.getHeightOfOneItem() * max;

      // TODO calculate the extra height of all other elements, which come before the max item, besides a dropdown items
      // e.i. div.separator-block, div.search-field, div.pseudo-link-buttons, item css gap?
      let extra = 5;

      if (ds?.data?.searchable) {
        // div.pseudo-link-buttons
        // eslint-disable-next-line
        extra += 38;
      }

      if (ds?.data?.showMassOperations) {
        // div.pseudo-link-buttons
        // eslint-disable-next-line
        extra += 18;
      }

      value = (height + extra) + 'px';
    }
    const host = this.elementRef?.nativeElement;
    return value;
  }

  private getHeightOfOneItem() {
    const host = this.elementRef?.nativeElement;
    const itemEl = host.querySelector('app-checkbox') as HTMLElement;
    const rect = itemEl?.getBoundingClientRect();
    return rect?.height;
  }

  private scrollToCheckboxWith(label: string) {
    this.dataSource?.actionEmitter.emit('scroll:' + label);
  }

  private sharedRequireValidationFn = (): ValidationErrors => {

    const errors: ValidationErrors = {};

    const selectedItems = this.dataSource?.selectedItems;

    if (!this.required || !selectedItems) {
      return;
    }

    const identity = 'dropdownRequired';

    let errorMessage: string;

    const isMulti = this.dataSource?.data.multi;
    const translationKey = 'dropdown.required' + (isMulti ? '_multi' : '_single');
    // const translationKey = 'common.yes';
    this.translationService.translate(translationKey).pipe(take(1)).subscribe(tr => {
      errorMessage = tr;
    });

    // just to make sure that both validators are alined
    this.requiredPseudoValidator.identity = identity;
    this.requiredPseudoValidator.errorMessage = errorMessage;

    const atLeastOneItemWithTruthyValue = selectedItems.some(item => {
      return item.value;
    });

    if (!atLeastOneItemWithTruthyValue) {
      errors[identity] = errorMessage;
    }

    return errors;
  };

}
